import { Box, Line, useTexture } from "@react-three/drei";
import { useThree, extend } from "@react-three/fiber";
import ScenarioGrid from "./../scenarios/single-vision/grid.jpg";
import ScenarioGridBlur from "./../scenarios/single-vision/grid-blur5.jpg";
import ScenarioOffice from "./../scenarios/single-vision/office.jpg";
import ScenarioOfficeBlur from "./../scenarios/single-vision/office-blur5.jpg";
import ScenarioSport from "./../scenarios/single-vision/sport/sport.jpg";
import ScenarioSportBlur from "./../scenarios/single-vision/sport/sport-blur5.jpg";
import ScenarioStreetAEye from '../scenarios/single-vision/street-aeye.jpg';
import ScenarioStreetAEyeBlur from '../scenarios/single-vision/street-aeye-blur5.jpg';
import ScenarioStreetBasic from '../scenarios/single-vision/street-basic.jpg';
import ScenarioStreetBasicBlur from '../scenarios/single-vision/street-basic-blur5.jpg';
import ScenarioStreetDNEye from '../scenarios/single-vision/street-dneye.jpg';
import ScenarioStreetDNEyeBlur from '../scenarios/single-vision/street-dneye-blur5.jpg';
import ScenarioStreetNetline from '../scenarios/single-vision/street-netline.jpg';
import ScenarioStreetNetlineBlur from '../scenarios/single-vision/street-netline-blur5.jpg';
import ScenarioStreetSensitive from '../scenarios/single-vision/street-sensitive.jpg';
import ScenarioStreetSensitiveBlur from '../scenarios/single-vision/street-sensitive-blur5.jpg';
import ScenarioSunAEye from '../scenarios/single-vision/sun/sun-aeye.jpg';
import ScenarioSunAEyeBlur from '../scenarios/single-vision/sun/sun-aeye-blur5.jpg';
import ScenarioSunBasic from '../scenarios/single-vision/sun/sun-basic.jpg';
import ScenarioSunBasicBlur from '../scenarios/single-vision/sun/sun-basic-blur5.jpg';
import ScenarioSunDNEye from '../scenarios/single-vision/sun/sun-dneye.jpg';
import ScenarioSunDNEyeBlur from '../scenarios/single-vision/sun/sun-dneye-blur5.jpg';
import ScenarioSunNetline from '../scenarios/single-vision/sun/sun-netline.jpg';
import ScenarioSunNetlineBlur from '../scenarios/single-vision/sun/sun-netline-blur5.jpg';
import FrameShape from "./../frame-shape/frame-shape-sv.png";
import { LensDistortionRoundMaterial } from "./materials/lens-distortion-round";
import { RadialDashMaterial } from "./materials/radial-dash";
import { RadiusTexMaterial } from "./materials/radius-texturing";
import { SingleVisionProductLensType } from "../../field-of-view/model";
import { useEffect, useState } from "react";
import { Vector3 } from "three";
import { FovFrameShade, FovRenderingMode } from "../field-of-view-renderer-shared";

extend({ LensDistortionRoundMaterial });
extend({ RadiusTexMaterial });
extend({ RadialDashMaterial });

/**
 * Single Vision scenarios
 */
export enum SVScenario {
  GRID = 0,
  OFFICE = 1,
  STREET = 2,
  SUN = 3,
  SPORT = 4,
}

/**
 * Generates an array of points for drawing a unit-circle
 * @param nbPoints Number of desired points
 * @returns Array of points
 */
function generateCirclePoints(nbPoints: number): Vector3[] {
  const points: Vector3[] = [];

  for (let angle = 0; angle < 2 * Math.PI; angle += (2 * Math.PI) / nbPoints) {
    points.push(new Vector3(Math.cos(angle), Math.sin(angle), 0.1));
  }
  points.push(new Vector3(1, 0, 0.1));
  return points;
}

const dashSize = 0.05;
const unitCirclePoints = generateCirclePoints(100);

/**
 * Properties definition for this component
 */
interface SingleVisionSceneProps {
  scenario: SVScenario;
  circleParams: number[];
  plusMode: boolean;
  distortionStrength: number;
  productLensType: SingleVisionProductLensType;
  renderingMode: FovRenderingMode;
  frameShade: FovFrameShade;
  fovLineColor: string;
}

/**
 * Contains geometry, textures and shaders to draw a WebGL scene displaying a single-vision FOV
 * @param props Properties for this component
 * @returns Single-vision FOV scene
 */
export function SingleVisionScene(props: SingleVisionSceneProps) {
  const textures = useTexture([
    ScenarioGrid,
    ScenarioOffice,
    ScenarioSport,
    ScenarioStreetSensitive,
    ScenarioStreetDNEye,
    ScenarioStreetAEye,
    ScenarioStreetBasic,
    ScenarioStreetNetline,
    ScenarioSunDNEye,
    ScenarioSunAEye,
    ScenarioSunBasic,
    ScenarioSunNetline,
  ]);
  const texturesBlur = useTexture([
    ScenarioGridBlur,
    ScenarioOfficeBlur,
    ScenarioSportBlur,
    ScenarioStreetSensitiveBlur,
    ScenarioStreetDNEyeBlur,
    ScenarioStreetAEyeBlur,
    ScenarioStreetBasicBlur,
    ScenarioStreetNetlineBlur,
    ScenarioSunDNEyeBlur,
    ScenarioSunAEyeBlur,
    ScenarioSunBasicBlur,
    ScenarioSunNetlineBlur,
  ]);
  const frameShapeTex = useTexture(FrameShape);
  const viewport = useThree((state) => state.viewport);
  const [textureIndex, setTextureIndex] = useState<number>(0);
  const [contentScale, setContentScale] = useState<Vector3>(new Vector3(1.0, 1.0, 1.0));
  const [fovLineVisibility, setFovLineVisibility] = useState<boolean>(true);

  /**
   * Update Effect - Used when the FOV circle has changed: toggles display of the FOV line ON or OFF
   */
  useEffect(() => {
    props.circleParams[1] >= 0.9 ? setFovLineVisibility(false) : setFovLineVisibility(true);
  }, [props.circleParams]);

  /**
   * Update Effect - Used when the rendering mode has changed
   */
  useEffect(() => {
    switch (props.renderingMode) {
      case FovRenderingMode.FRAME_SHAPE:
        setContentScale(new Vector3(0.8, 0.8, 1.0)); // Round frame shape
        // setContentScale(new Vector3(0.7, 0.7, 1.0)); // Standard frame shape
        break;

      default:
        setContentScale(new Vector3(1.0, 1.0, 1.0));
        break;
    }
  }, [props.renderingMode]);

  /**
   * Update Effect - Used when the product lens type or scenarion has changed
   */
  useEffect(() => {
    switch (props.scenario) {
      case SVScenario.GRID:
        setTextureIndex(0);
        break;

      case SVScenario.OFFICE:
        setTextureIndex(1);
        break;

      case SVScenario.SPORT:
        setTextureIndex(2);
        break;

      case SVScenario.SUN:
        switch (props.productLensType) {
          case SingleVisionProductLensType.IMPRESSION_DNEYE_MONO:
          case SingleVisionProductLensType.MULTIGRESSIV_DNEYE_MONO:
          case SingleVisionProductLensType.COSMOLIT_DNEYE_MONO:
            setTextureIndex(8);
            break;

          case SingleVisionProductLensType.IMPRESSION_AEYE_MONO:
          case SingleVisionProductLensType.MULTIGRESSIV_AEYE_MONO:
          case SingleVisionProductLensType.COSMOLIT_AEYE_MONO:
            setTextureIndex(9);
            break;

          case SingleVisionProductLensType.COSMOLIT:
          case SingleVisionProductLensType.PERFALIT:
            setTextureIndex(10);
            break;

          case SingleVisionProductLensType.PUNKTULIT:
          case SingleVisionProductLensType.PUNKTULIT_AS:
            setTextureIndex(11);
            break;
        }
        break;

      default:
        switch (props.productLensType) {
          case SingleVisionProductLensType.IMPRESSION_SENSITIVE_MONO:
            setTextureIndex(3);
            break;

          case SingleVisionProductLensType.IMPRESSION_DNEYE_MONO:
          case SingleVisionProductLensType.MULTIGRESSIV_DNEYE_MONO:
          case SingleVisionProductLensType.COSMOLIT_DNEYE_MONO:
            setTextureIndex(4);
            break;

          case SingleVisionProductLensType.IMPRESSION_AEYE_MONO:
          case SingleVisionProductLensType.MULTIGRESSIV_AEYE_MONO:
          case SingleVisionProductLensType.COSMOLIT_AEYE_MONO:
            setTextureIndex(5);
            break;

          case SingleVisionProductLensType.COSMOLIT:
          case SingleVisionProductLensType.PERFALIT:
            setTextureIndex(6);
            break;

          case SingleVisionProductLensType.PUNKTULIT:
          case SingleVisionProductLensType.PUNKTULIT_AS:
            setTextureIndex(7);
            break;
        }
        break;
    }
  }, [props.scenario, props.productLensType]);

  return (
    <>
      <Box args={[viewport.width, viewport.height, 0]} position={[0, 0, -1]} scale={contentScale}>
        <lensDistortionRoundMaterial
          map={textures[textureIndex]}
          mapBlur={texturesBlur[textureIndex]}
          fovRadius={props.circleParams[1]}
          fovYOffset={props.circleParams[0]}
          distortionStrength={props.distortionStrength}
          plusMinusFactor={props.plusMode ? -0.8 : 1.0}
        />
      </Box>
      <Line
        points={unitCirclePoints}
        position={[0, (contentScale.y * props.circleParams[0] * viewport.height) / 8, -1]}
        scale={
          new Vector3(
            (contentScale.x * (props.circleParams[1] * viewport.width)) / 2,
            (contentScale.y * (props.circleParams[1] * viewport.width)) / 2,
            contentScale.z
          )
        }
        dashed
        dashSize={dashSize}
        gapSize={dashSize}
        color={props.fovLineColor}
        lineWidth={4}
        visible={fovLineVisibility}
      />
      <Line
        points={unitCirclePoints}
        scale={
          new Vector3(
            (contentScale.x * 0.9 * viewport.width) / 2,
            (contentScale.y * 0.9 * viewport.width) / 2,
            contentScale.z
          )
        }
        color={props.frameShade === FovFrameShade.LIGHT ? "white" : "black"}
        lineWidth={viewport.width / 500}
      />

      <Box
        args={[viewport.width, viewport.height, 0]}
        position={[0, 0, -1]}
        visible={props.renderingMode === FovRenderingMode.FRAME_SHAPE}
      >
        <meshBasicMaterial
          alphaMap={frameShapeTex}
          transparent={true}
          color={props.frameShade === FovFrameShade.LIGHT ? "white" : "black"}
        />
      </Box>
    </>
  );
}
