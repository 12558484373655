export interface FieldOfViewCoordinate {
  x: number;
  y: number;
  valid: number;
}

export interface FoVInputParameters {
  addition1: number;
  addition2: number;
  pantoscopicTilt: number;
  faceFormAngle: number;
  corneaVertexDistance: number;
  pupillaryDistance: number;
  df: number;
  dn1: number;
  dn2: number;
  designCharacteristicFar: number;
  designCharacteristicIntermediate: number;
  designCharacteristicNear: number;
  farDistance: number;
  nearDistance1: number;
  nearDistance2: number;
  xSeparationFactor1: number;
  xSeparationFactor2: number;
  verticalOffset1: number;
  verticalOffset2: number;
}

export interface InputLayerParameters {
  addition: number;
  pantoscopicTilt: number;
  faceFormAngle: number;
  corneaVertexDistance: number;
  pupillaryDistance: number;
  df: number;
  dn: number;
  designCharacteristicFar: number;
  designCharacteristicIntermediate: number;
  designCharacteristicNear: number;
  farDistance: number;
  nearDistance: number;
}

export interface FeatureScale {
  location: number;
  scale: number;
}

export interface FoVModelFeatureScaler {
  ADD: FeatureScale;
  PT: FeatureScale;
  FFA: FeatureScale;
  CVD: FeatureScale;
  PD: FeatureScale;
  DF: FeatureScale;
  DN: FeatureScale;
  DC_F: FeatureScale;
  DC_I: FeatureScale;
  DC_N: FeatureScale;
  // eslint-disable-next-line camelcase
  Dist_F: FeatureScale;
  // eslint-disable-next-line camelcase
  Dist_N: FeatureScale;
  y: FeatureScale;
}

export interface DoFModelFeatureScaler {
  ADD: FeatureScale;
  PT: FeatureScale;
  FFA: FeatureScale;
  CVD: FeatureScale;
  PD: FeatureScale;
  DF: FeatureScale;
  DN: FeatureScale;
  DC_F: FeatureScale;
  DC_I: FeatureScale;
  DC_N: FeatureScale;
  // eslint-disable-next-line camelcase
  Dist_F: FeatureScale;
  // eslint-disable-next-line camelcase
  Dist_N: FeatureScale;
  proximity: FeatureScale;
}

export interface FormInputParameter {
  name:
    | "addition1"
    | "addition2"
    | "pantoscopicTilt"
    | "faceFormAngle"
    | "corneaVertexDistance"
    | "pupillaryDistance"
    | "df"
    | "dn1"
    | "dn2"
    | "designCharacteristicFar"
    | "designCharacteristicIntermediate"
    | "designCharacteristicNear"
    | "farDistance"
    | "nearDistance1"
    | "nearDistance2"
    | "xSeparationFactor1"
    | "xSeparationFactor2"
    | "verticalOffset1"
    | "verticalOffset2";
  title: string;
  step: number;
  min: number;
  max: number;
  readOnly: boolean;
  disabled: boolean;
}

export interface FormInputParameterSingleVision {
  name:
    | "pantoscopicTilt"
    | "faceFormAngle"
    | "corneaVertexDistance"
    | "pupillaryDistance";
  title: string;
  step: number;
  min: number;
  max: number;
  readOnly: boolean;
  disabled: boolean;
}

export enum LensType {
  NEAR_VISION = 0,
  SINGLE_VISION = 1,
  PROGRESSIVE = 2,
  SPORT = 3,
}

// export enum SVLensType {
//   SINGLE_VISION = 0,
//   SINGLE_VISION_SPORT = 1,
// }

export enum NearVisionProductLensType {
  IMPRESSION_SENSITIVE_ERGO = 0,
  IMPRESSION_DNEYE_ERGO = 1,
  MULTIGRESSIV_DNEYE_ERGO = 2,
  PROGRESSIV_DNEYE_ERGO = 3,
  IMPRESSION_AEYE_ERGO = 4,
  MULTIGRESSIV_AEYE_ERGO = 5,
  PROGRESSIV_AEYE_ERGO = 6,
  PROGRESSIV_ERGO = 7,
  NETLINE_ROOM = 8,
  NETLINE_WORK1 = 9,
  NETLINE_WORK2 = 10,
  NETLINE_40 = 11,
  NONE = 12,
}

export enum ProgressiveProductLensType {
  IMPRESSION_SENSITIVE = 0,
  IMPRESSION_DNEYE = 1,
  MULTIGRESSIV_DNEYE = 2,
  PROGRESSIV_DNEYE = 3,
  IMPRESSION_AEYE = 4,
  MULTIGRESSIV_AEYE = 5,
  PROGRESSIV_AEYE = 6,
  PROGRESSIV_LIFE = 7,
  PROGRESSIV_FREE_PRO = 8,
  PROGRESSIV_FREE = 9,
  PROGRESSIV_SI = 10,
  NONE = 11,
}

export enum SingleVisionProductLensType {
  IMPRESSION_SENSITIVE_MONO = 0,
  IMPRESSION_DNEYE_MONO = 1,
  MULTIGRESSIV_DNEYE_MONO = 2,
  COSMOLIT_DNEYE_MONO = 3,
  IMPRESSION_AEYE_MONO = 4,
  MULTIGRESSIV_AEYE_MONO = 5,
  COSMOLIT_AEYE_MONO = 6,
  COSMOLIT = 7,
  PERFALIT = 8,
  PUNKTULIT_AS = 9,
  PUNKTULIT = 10,
  NONE = 11,
}

export enum SportProductLensType {
  IMPRESSION_DNEYE_SPORT = 0,
  IMPRESSION_AEYE_SPORT = 1,
  PROGRESSIV_SPORT = 2,
  IMPRESSION_DNEYE_MONO_SPORT = 3,
  IMPRESSION_AEYE_MONO_SPORT = 4,
  PERFALIT_SPORT = 5,
  NONE = 10,
}

// export enum SingleVisionSportProductLensType {
//   IMPRESSION_DNEYE_MONO_SPORT = 0,
//   IMPRESSION_AEYE_MONO_SPORT = 1,
//   PERFALIT_SPORT = 2,
//   NONE = 10,
// }