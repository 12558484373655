import { LayersModel, loadLayersModel, ready, Tensor2D, tensor2d, serialization } from "@tensorflow/tfjs";
import { useEffect, useState } from "react";

import {
  depthLayersProximity,
  FieldOfViewRendererProgressive,
  FovDisplayMode,
} from "../field-of-view-renderers/field-of-view-renderer-progressive";
import { FovFrameShade, FovRenderingMode } from "../field-of-view-renderers/field-of-view-renderer-shared";
import DoFModelFeatureScalerData from "./tensorflow/dof_model_feature_scaler_data.json";
import FoVModelFeatureScalerData from "./tensorflow/fov_model_feature_scaler_data.json";
import {
  DoFModelFeatureScaler,
  FieldOfViewCoordinate,
  FoVInputParameters,
  FoVModelFeatureScaler,
  InputLayerParameters,
  LensType,
  NearVisionProductLensType,
  ProgressiveProductLensType,
  SingleVisionProductLensType,
  SportProductLensType,
} from "./model";
import { calculateSVCircle, SVParameters } from "../field-of-view-calculations/single-vision-calculations";
import { FieldOfViewRendererWebGL } from "../field-of-view-renderers/field-of-view-renderer-webgl";
import { SVScenario } from "../field-of-view-renderers/webGL/single-vision-scene";
import { DecreasingDenseLayer } from "./tensorflow/decreasing-dense-layer.js";

export { FovDisplayMode, FovRenderingMode, FovFrameShade };

interface FieldOfViewProps {
  fovInputParameters: FoVInputParameters;
  lensType: LensType;
  productLensType1?:
    | NearVisionProductLensType
    | ProgressiveProductLensType
    | SingleVisionProductLensType
    | SportProductLensType;
  productLensType2?:
    | NearVisionProductLensType
    | ProgressiveProductLensType
    | SingleVisionProductLensType
    | SportProductLensType;
  displayMode: FovDisplayMode;
  renderingMode: FovRenderingMode;
  frameShade: FovFrameShade;
  topMargin?: boolean;
  sunMode: boolean;
  elementId: string;
  newSVScenario: SVScenario;
  newSVPlusMode: boolean;
}

/**
 * Regsiter the custom layer, so TensorFlow.js knows what class constructor
 * to call when deserializing an saved instance of the custom layer.
 */
serialization.registerClass(DecreasingDenseLayer);

const foVModelFeatureScaler: FoVModelFeatureScaler = FoVModelFeatureScalerData;
const doFModelFeatureScaler: DoFModelFeatureScaler = DoFModelFeatureScalerData;

// Step between each y coordinate in [-25; 25]
// 1 => 50 coordinates (Standard)
// 0.5 => 100 coordinates
// 0.25 => 200 coordinates
// 0.125 => 400 coordinates
let yStep = 1;

// Normalize input parameter values
//   input_layer = (real_input - location) / scale
function normalize(input: InputLayerParameters): InputLayerParameters {
  return {
    addition: (input.addition - foVModelFeatureScaler.ADD.location) / foVModelFeatureScaler.ADD.scale,
    pantoscopicTilt: (input.pantoscopicTilt - foVModelFeatureScaler.PT.location) / foVModelFeatureScaler.PT.scale,
    faceFormAngle: (input.faceFormAngle - foVModelFeatureScaler.FFA.location) / foVModelFeatureScaler.FFA.scale,
    corneaVertexDistance:
      (input.corneaVertexDistance - foVModelFeatureScaler.CVD.location) / foVModelFeatureScaler.CVD.scale,
    pupillaryDistance: (input.pupillaryDistance - foVModelFeatureScaler.PD.location) / foVModelFeatureScaler.PD.scale,
    df: (input.df - foVModelFeatureScaler.DF.location) / foVModelFeatureScaler.DF.scale,
    dn: (input.dn - foVModelFeatureScaler.DN.location) / foVModelFeatureScaler.DN.scale,
    designCharacteristicFar:
      (input.designCharacteristicFar - foVModelFeatureScaler.DC_F.location) / foVModelFeatureScaler.DC_F.scale,
    designCharacteristicIntermediate:
      (input.designCharacteristicIntermediate - foVModelFeatureScaler.DC_I.location) / foVModelFeatureScaler.DC_I.scale,
    designCharacteristicNear:
      (input.designCharacteristicNear - foVModelFeatureScaler.DC_N.location) / foVModelFeatureScaler.DC_N.scale,
    farDistance: (input.farDistance - foVModelFeatureScaler.Dist_F.location) / foVModelFeatureScaler.Dist_F.scale,
    nearDistance: (input.nearDistance - foVModelFeatureScaler.Dist_N.location) / foVModelFeatureScaler.Dist_N.scale,
  };
}

function tidy(model: LayersModel, input: InputLayerParameters[]): Tensor2D {
  let inputAndYCoordinate: number[] = [];

  let valArray: number[] = [];
  input.forEach((inputLayer) => {
    const {
      addition,
      pantoscopicTilt,
      faceFormAngle,
      corneaVertexDistance,
      pupillaryDistance,
      df,
      dn,
      designCharacteristicFar,
      designCharacteristicIntermediate,
      designCharacteristicNear,
      farDistance,
      nearDistance,
    } = normalize(inputLayer);

    valArray = [
      addition,
      pantoscopicTilt,
      faceFormAngle,
      corneaVertexDistance,
      pupillaryDistance,
      df,
      dn,
      designCharacteristicFar,
      designCharacteristicIntermediate,
      designCharacteristicNear,
      farDistance,
      nearDistance,
    ];

    for (let i = -25; i <= 25; i += yStep) {
      inputAndYCoordinate = inputAndYCoordinate.concat([...valArray, i / 25.0]);
    }
  });

  // console.time("Determination of x coordinates took");

  const output: Tensor2D = model?.predict(tensor2d(inputAndYCoordinate, [100 * (1 / yStep) + 2, 13])) as Tensor2D;

  // console.timeEnd("Determination of x coordinates took");

  return output;
}

function predictValues(model: LayersModel, fovInputParameters: FoVInputParameters) {
  if (model) {
    return tidy(model, [
      {
        addition: fovInputParameters.addition1,
        pantoscopicTilt: fovInputParameters.pantoscopicTilt,
        faceFormAngle: fovInputParameters.faceFormAngle,
        corneaVertexDistance: fovInputParameters.corneaVertexDistance,
        pupillaryDistance: fovInputParameters.pupillaryDistance,
        df: fovInputParameters.df,
        dn: fovInputParameters.dn1,
        designCharacteristicFar: fovInputParameters.designCharacteristicFar,
        designCharacteristicIntermediate: fovInputParameters.designCharacteristicIntermediate,
        designCharacteristicNear: fovInputParameters.designCharacteristicNear,
        farDistance: fovInputParameters.farDistance,
        nearDistance: fovInputParameters.nearDistance1,
      },
      {
        addition: fovInputParameters.addition2,
        pantoscopicTilt: fovInputParameters.pantoscopicTilt,
        faceFormAngle: fovInputParameters.faceFormAngle,
        corneaVertexDistance: fovInputParameters.corneaVertexDistance,
        pupillaryDistance: fovInputParameters.pupillaryDistance,
        df: fovInputParameters.df,
        dn: fovInputParameters.dn2,
        designCharacteristicFar: fovInputParameters.designCharacteristicFar,
        designCharacteristicIntermediate: fovInputParameters.designCharacteristicIntermediate,
        designCharacteristicNear: fovInputParameters.designCharacteristicNear,
        farDistance: fovInputParameters.farDistance,
        nearDistance: fovInputParameters.nearDistance2,
      },
    ]);
  }

  return undefined;
}

function tidyDoF(model: LayersModel, input: InputLayerParameters): Tensor2D {
  const {
    addition,
    pantoscopicTilt,
    faceFormAngle,
    corneaVertexDistance,
    pupillaryDistance,
    df,
    dn,
    designCharacteristicFar,
    designCharacteristicIntermediate,
    designCharacteristicNear,
    farDistance,
    nearDistance,
  } = normalize(input);

  let valArray: number[] = [];
  valArray = [
    addition,
    pantoscopicTilt,
    faceFormAngle,
    corneaVertexDistance,
    pupillaryDistance,
    df,
    dn,
    designCharacteristicFar,
    designCharacteristicIntermediate,
    designCharacteristicNear,
    farDistance,
    nearDistance,
  ];

  // Proximity = 1/ Distance (in meters)
  let inputAndProximity: number[] = [];
  for (let i = 0; i < 8; i += 1) {
    inputAndProximity = inputAndProximity.concat([
      ...valArray,
      (depthLayersProximity[i] - doFModelFeatureScaler.proximity.location) / doFModelFeatureScaler.proximity.scale,
    ]);
  }

  const output: Tensor2D = model?.predict(tensor2d(inputAndProximity, [8, 13])) as Tensor2D;
  return output;
}

function predictDoFValues(model: LayersModel, fovInputParameters: FoVInputParameters) {
  if (model) {
    return tidyDoF(model, {
      addition: fovInputParameters.addition1,
      pantoscopicTilt: fovInputParameters.pantoscopicTilt,
      faceFormAngle: fovInputParameters.faceFormAngle,
      corneaVertexDistance: fovInputParameters.corneaVertexDistance,
      pupillaryDistance: fovInputParameters.pupillaryDistance,
      df: fovInputParameters.df,
      dn: fovInputParameters.dn1,
      designCharacteristicFar: fovInputParameters.designCharacteristicFar,
      designCharacteristicIntermediate: fovInputParameters.designCharacteristicIntermediate,
      designCharacteristicNear: fovInputParameters.designCharacteristicNear,
      farDistance: fovInputParameters.farDistance,
      nearDistance: fovInputParameters.nearDistance1,
    });
  }

  return undefined;
}

function retrieveDoFValues(
  output: Tensor2D,
  productLensType?:
    | NearVisionProductLensType
    | ProgressiveProductLensType
    | SingleVisionProductLensType
    | SportProductLensType
): number[] {
  const result: number[] = [];
  const dofValues: number[][] = output.arraySync();

  if (productLensType !== undefined) {
    for (let i = 0; i < 8; i += 1) {
      result.push(dofValues[i][productLensType]);
    }
  }

  return result;
}

function mapProgProductLensTypeToNewTFModel(productLensType: ProgressiveProductLensType): number | undefined {
  switch (productLensType) {
    case ProgressiveProductLensType.IMPRESSION_SENSITIVE:
    case ProgressiveProductLensType.IMPRESSION_DNEYE:
      return 0;

    case ProgressiveProductLensType.IMPRESSION_AEYE:
      return 1;

    case ProgressiveProductLensType.MULTIGRESSIV_DNEYE:
      return 2;

    case ProgressiveProductLensType.MULTIGRESSIV_AEYE:
      return 3;

    case ProgressiveProductLensType.PROGRESSIV_DNEYE:
      return 4;

    case ProgressiveProductLensType.PROGRESSIV_AEYE:
      return 5;

    case ProgressiveProductLensType.PROGRESSIV_LIFE:
      return 6;

    case ProgressiveProductLensType.PROGRESSIV_FREE_PRO:
      return 7;

    case ProgressiveProductLensType.PROGRESSIV_FREE:
      return 8;

    case ProgressiveProductLensType.PROGRESSIV_SI:
      return 9;

    default:
      return undefined;
  }
}

function mapNVProductLensTypeToNewTFModel(productLensType: NearVisionProductLensType): number | undefined {
  switch (productLensType) {
    case NearVisionProductLensType.IMPRESSION_SENSITIVE_ERGO:
    case NearVisionProductLensType.IMPRESSION_DNEYE_ERGO:
      return 0;

    case NearVisionProductLensType.IMPRESSION_AEYE_ERGO:
      return 1;

    case NearVisionProductLensType.MULTIGRESSIV_DNEYE_ERGO:
      return 2;

    case NearVisionProductLensType.MULTIGRESSIV_AEYE_ERGO:
      return 3;

    case NearVisionProductLensType.PROGRESSIV_DNEYE_ERGO:
      return 4;

    case NearVisionProductLensType.PROGRESSIV_AEYE_ERGO:
      return 5;

    case NearVisionProductLensType.PROGRESSIV_ERGO:
      return 6;

    case NearVisionProductLensType.NETLINE_ROOM:
    case NearVisionProductLensType.NETLINE_WORK1:
    case NearVisionProductLensType.NETLINE_WORK2:
    case NearVisionProductLensType.NETLINE_40:
      return 7;

    default:
      return undefined;
  }
}

function mapProductLensTypeToDOFModel(productLensType: NearVisionProductLensType): number {
  if (productLensType === NearVisionProductLensType.NETLINE_ROOM) return 7;

  if (
    productLensType === NearVisionProductLensType.NETLINE_WORK1 ||
    productLensType === NearVisionProductLensType.NETLINE_WORK2
  )
    return 8;

  if (productLensType === NearVisionProductLensType.NETLINE_40) return 9;

  return productLensType;
}

function calculateFieldOfViewCoordinates(
  output: Tensor2D,
  lensType: LensType,
  productLensType1?:
    | NearVisionProductLensType
    | ProgressiveProductLensType
    | SingleVisionProductLensType
    | SportProductLensType,
  productLensType2?:
    | NearVisionProductLensType
    | ProgressiveProductLensType
    | SingleVisionProductLensType
    | SportProductLensType
): [FieldOfViewCoordinate[], FieldOfViewCoordinate[]] {
  const coordinates1: FieldOfViewCoordinate[] = [];
  const coordinates2: FieldOfViewCoordinate[] = [];

  // Pairs of columns of x and corresponding valid values for several lens types
  const fieldOfViewParameters: number[][] = output.arraySync();

  var xSeparationFactor1 = 0;
  var xSeparationFactor2 = 0;

  if (lensType === LensType.PROGRESSIVE) {
    if(productLensType1 as ProgressiveProductLensType === ProgressiveProductLensType.IMPRESSION_SENSITIVE)
      xSeparationFactor1 = 2.5;
    if(productLensType2 as ProgressiveProductLensType === ProgressiveProductLensType.IMPRESSION_SENSITIVE)
      xSeparationFactor2 = 2.5;

    productLensType1 = mapProgProductLensTypeToNewTFModel(productLensType1 as ProgressiveProductLensType);
    productLensType2 = mapProgProductLensTypeToNewTFModel(productLensType2 as ProgressiveProductLensType);
  } else if (lensType === LensType.NEAR_VISION) {
    if(productLensType1 as NearVisionProductLensType === NearVisionProductLensType.IMPRESSION_SENSITIVE_ERGO)
      xSeparationFactor1 = 2.5;
    if(productLensType2 as NearVisionProductLensType === NearVisionProductLensType.IMPRESSION_SENSITIVE_ERGO)
      xSeparationFactor2 = 2.5;

    productLensType1 = mapNVProductLensTypeToNewTFModel(productLensType1 as NearVisionProductLensType);
    productLensType2 = mapNVProductLensTypeToNewTFModel(productLensType2 as NearVisionProductLensType);
  }

  // Iterate on every even index: the uneven ones hold the 'valid' flag
  for (let i = 0, y = -25; i < output.shape[0] / 2 - 1; i += 1, y += yStep) {
    if (productLensType1 !== undefined) {
      const valid1 = fieldOfViewParameters[i][productLensType1 * 2 + 1];

      if (valid1 > 0) {
        coordinates1.push({
          x: fieldOfViewParameters[i][productLensType1 * 2] + xSeparationFactor1,
          y: y,
          valid: valid1,
        });
      }
    }

    if (productLensType2 !== undefined) {
      const valid2 = fieldOfViewParameters[i + output.shape[0] / 2][productLensType2 * 2 + 1];

      if (valid2 > 0) {
        coordinates2.push({
          x: fieldOfViewParameters[i + output.shape[0] / 2][productLensType2 * 2] + xSeparationFactor2,
          y: y,
          valid: valid2,
        });
      }
    }
  }
  return [coordinates1, coordinates2];
}

async function loadModel(filePath: string) {
  try {
    const model: LayersModel = await loadLayersModel(filePath, {
      strict: true,
    });
    return model;
  } catch (err) {
    console.log(err);
  }

  return undefined;
}

function getInternalFOVParams(
  productLensType: NearVisionProductLensType,
  externalParams: FoVInputParameters,
  secondProduct: boolean
): FoVInputParameters {
  const internalFOVParams = externalParams;

  switch (productLensType) {
    case NearVisionProductLensType.NETLINE_ROOM:
      internalFOVParams.designCharacteristicFar = 71;
      internalFOVParams.designCharacteristicIntermediate = 14;
      internalFOVParams.designCharacteristicNear = 14;
      internalFOVParams.df = -2;
      if (secondProduct) {
        internalFOVParams.dn2 = -18;
        internalFOVParams.nearDistance2 = 40;
      } else {
        internalFOVParams.dn1 = -18;
        internalFOVParams.nearDistance1 = 40;
      }
      internalFOVParams.farDistance = 190;
      break;

    case NearVisionProductLensType.NETLINE_WORK1:
      internalFOVParams.designCharacteristicFar = 14;
      internalFOVParams.designCharacteristicIntermediate = 71;
      internalFOVParams.designCharacteristicNear = 14;
      internalFOVParams.df = 0;
      if (secondProduct) {
        internalFOVParams.dn2 = -18;
        internalFOVParams.nearDistance2 = 40;
        internalFOVParams.addition2 = 1.75;
      } else {
        internalFOVParams.dn1 = -18;
        internalFOVParams.nearDistance1 = 40;
        internalFOVParams.addition1 = 1.75;
      }
      internalFOVParams.farDistance = 110;
      break;

    case NearVisionProductLensType.NETLINE_WORK2:
      internalFOVParams.designCharacteristicFar = 14;
      internalFOVParams.designCharacteristicIntermediate = 71;
      internalFOVParams.designCharacteristicNear = 14;
      internalFOVParams.df = 0;
      if (secondProduct) {
        internalFOVParams.dn2 = -18;
        internalFOVParams.nearDistance2 = 40;
        internalFOVParams.addition2 = 2.5;
      } else {
        internalFOVParams.dn1 = -18;
        internalFOVParams.nearDistance1 = 40;
        internalFOVParams.addition1 = 2.5;
      }
      internalFOVParams.farDistance = 110;
      break;

    case NearVisionProductLensType.NETLINE_40:
      internalFOVParams.designCharacteristicFar = 14;
      internalFOVParams.designCharacteristicIntermediate = 14;
      internalFOVParams.designCharacteristicNear = 71;
      internalFOVParams.df = 0;
      if (secondProduct) {
        internalFOVParams.dn2 = -14;
        internalFOVParams.nearDistance2 = 40;
        internalFOVParams.addition2 = 2.5;
      } else {
        internalFOVParams.dn1 = -14;
        internalFOVParams.nearDistance1 = 40;
        internalFOVParams.addition1 = 2.5;
      }
      internalFOVParams.farDistance = 86;
      break;

    default:
      break;
  }

  return internalFOVParams;
}

export function FieldOfView(props: FieldOfViewProps) {
  // console.log(`LensType${props.productLensType1}`);
  // console.log(`Add:${props.fovInputParameters.addition1}`);
  // console.log(`PT:${props.fovInputParameters.pantoscopicTilt}`);
  // console.log(`FFA:${props.fovInputParameters.faceFormAngle}`);
  // console.log(`CVD:${props.fovInputParameters.corneaVertexDistance}`);
  // console.log(`PD:${props.fovInputParameters.pupillaryDistance}`);
  // console.log(`DF:${props.fovInputParameters.df}`);
  // console.log(`DN1:${props.fovInputParameters.dn1}`);
  // console.log(`DN2:${props.fovInputParameters.dn2}`);
  // console.log(`DC_FAR:${props.fovInputParameters.designCharacteristicFar}`);
  // console.log(
  //   `DC_MIDDLE:${props.fovInputParameters.designCharacteristicIntermediate}`
  // );
  // console.log(`DC_NEAR:${props.fovInputParameters.designCharacteristicNear}`);
  // console.log(`Distance FAR:${props.fovInputParameters.farDistance}`);
  // console.log(`Distance NEAR 1:${props.fovInputParameters.nearDistance1}`);
  // console.log(`Distance NEAR 2:${props.fovInputParameters.nearDistance2}`);

  const [foVModel, setFoVModel] = useState<LayersModel | undefined>();
  const [doFModel, setDoFModel] = useState<LayersModel | undefined>();
  const [fov1Coords, setFov1Coords] = useState<FieldOfViewCoordinate[]>([]);
  const [fov2Coords, setFov2Coords] = useState<FieldOfViewCoordinate[]>([]);

  useEffect(() => {
    ready().then(async () => {
      switch (props.lensType) {
        case LensType.NEAR_VISION:
          yStep = 1;
          setFoVModel(await loadModel("/assets/fov-model/near_vision/model.json"));
          setDoFModel(await loadModel("/assets/dof-model/near_vision/model.json"));
          break;

        case LensType.PROGRESSIVE:
          yStep = 1;
          setFoVModel(await loadModel("/assets/fov-model/progressive/model.json"));
          setDoFModel(undefined);
          break;

        case LensType.SINGLE_VISION:
        case LensType.SPORT:
          setFoVModel(undefined);
          setDoFModel(undefined);
          break;

        default:
          break;
      }
    });
  }, [props.lensType, props.productLensType1]);

  useEffect(() => {
    // Predict & format FOV coordinates
    let inputParams = props.fovInputParameters;
    let outputFoV;

    if (props.lensType === LensType.NEAR_VISION) {
      inputParams = getInternalFOVParams(
        props.productLensType1 as NearVisionProductLensType,
        props.fovInputParameters,
        false
      );

      outputFoV = foVModel ? predictValues(foVModel, inputParams) : undefined;
      const [coords1] = outputFoV
        ? calculateFieldOfViewCoordinates(
            outputFoV,
            props.lensType,
            props.productLensType1,
            props.productLensType2
          )
        : [[], []];

      setFov1Coords(coords1);

      inputParams = getInternalFOVParams(
        props.productLensType2 as NearVisionProductLensType,
        props.fovInputParameters,
        true
      );

      outputFoV = foVModel ? predictValues(foVModel, inputParams) : undefined;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [dummy, coords2] = outputFoV
        ? calculateFieldOfViewCoordinates(
            outputFoV,
            props.lensType,
            props.productLensType1,
            props.productLensType2
          )
        : [[], []];

      setFov2Coords(coords2);
    } else {
      outputFoV = foVModel ? predictValues(foVModel, props.fovInputParameters) : undefined;
      const [coords1, coords2] = outputFoV
        ? calculateFieldOfViewCoordinates(
            outputFoV,
            props.lensType,
            props.productLensType1,
            props.productLensType2
          )
        : [[], []];

      /* const coords1Str = JSON.stringify(coords1);
      const coords2Str = JSON.stringify(coords2);

      if (coords1Str === coords2Str) {
        console.log("Coordinates are the same");
      } else {
        console.log("Coordinates are different");
      }
      */

      setFov1Coords(coords1);
      setFov2Coords(coords2);
    }
  }, [
    foVModel,
    props.lensType,
    props.fovInputParameters,
    props.productLensType1,
    props.productLensType2,
  ]);

  // Predict & retrieve DOF for Near Vision Lenses
  const outputDoF = doFModel ? predictDoFValues(doFModel, props.fovInputParameters) : undefined;
  let doFValues = [1, 1, 1, 1, 1, 1, 1, 1];
  if (outputDoF) {
    // Netline: need to map new product depending on corresponding DC
    let product1 = mapProductLensTypeToDOFModel(props.productLensType1 as NearVisionProductLensType);
    let product2 = mapProductLensTypeToDOFModel(props.productLensType2 as NearVisionProductLensType);
    doFValues = retrieveDoFValues(outputDoF!, product1 ?? product2);
  }

  // New SV FOV calculations
  const svParams1: SVParameters = {
    pantoscopicTilt: props.fovInputParameters.pantoscopicTilt,
    faceFormAngle: props.fovInputParameters.faceFormAngle,
    corneaVertexDistance: props.fovInputParameters.corneaVertexDistance,
    productLensType: props.productLensType1 as SingleVisionProductLensType,
  };
  const svCircleParams1 = props.lensType === LensType.SINGLE_VISION ? calculateSVCircle(svParams1) : [0, 0];

  const svParams2: SVParameters = {
    pantoscopicTilt: props.fovInputParameters.pantoscopicTilt,
    faceFormAngle: props.fovInputParameters.faceFormAngle,
    corneaVertexDistance: props.fovInputParameters.corneaVertexDistance,
    productLensType: props.productLensType2 as SingleVisionProductLensType,
  };
  const svCircleParams2 = props.lensType === LensType.SINGLE_VISION ? calculateSVCircle(svParams2) : [0, 0];
  const distortionStrength = 1;

  return (
    <>
      {props.lensType === LensType.SINGLE_VISION ? (
        <FieldOfViewRendererWebGL
          topMargin={props.topMargin}
          scenario={props.newSVScenario}
          circleParams1={svCircleParams1}
          circleParams2={svCircleParams2}
          plusMode={props.newSVPlusMode}
          distortionStrength={distortionStrength}
          productLensType1={props.productLensType1 as SingleVisionProductLensType}
          productLensType2={props.productLensType2 as SingleVisionProductLensType}
          renderingMode={props.renderingMode}
          frameShade={props.frameShade}
        />
      ) : (
        <FieldOfViewRendererProgressive
          coordinates1={fov1Coords}
          coordinates2={fov2Coords}
          dofValues={doFValues}
          lensType={props.lensType}
          productLensType1={props.productLensType1}
          displayMode={props.displayMode}
          renderingMode={props.renderingMode}
          frameShade={props.frameShade}
          dF={props.fovInputParameters.df}
          dN={props.fovInputParameters.dn1}
          topMargin={props.topMargin}
          sunMode={props.sunMode}
          elementId={props.elementId}
        />
      )}
    </>
  );
}
