import { useEffect, useState } from "react";
import {
  FoVInputParameters,
  FormInputParameter,
  NearVisionProductLensType,
  ProgressiveProductLensType,
  SingleVisionProductLensType,
  SportProductLensType,
  LensType,
} from "../field-of-view/model";
import { makeStyles } from "@material-ui/core/styles";
import { Input, Grid, MenuItem, Select, Slider, Checkbox } from "@material-ui/core";
import { useForm, useWatch, Controller } from "react-hook-form";
import { LensDesignCharacteristic } from "../field-of-view-calculations/lens-design-calculations";
import { FieldOfView, FovDisplayMode, FovRenderingMode, FovFrameShade } from "../field-of-view/field-of-view";
import { SVScenario } from "../field-of-view-renderers/webGL/single-vision-scene";

export function FieldOfViewProgressiveForm() {
  const useStyles = makeStyles((theme: any) => ({
    input: {
      width: 50,
    },
    slider: {
      width: 150,
    },
    formControl: {
      textAlign: "left",
      width: "100%",
    },
  }));
  const classes = useStyles();

  const [lensType, setLensType] = useState<LensType>(LensType.NEAR_VISION);
  const [renderingMode, setRenderingMode] = useState<FovRenderingMode>(FovRenderingMode.FULL);
  const [sunMode, setSunMode] = useState<boolean>(false);

  const [productLensType1, setProductLensType1] = useState<NearVisionProductLensType | ProgressiveProductLensType>(
    NearVisionProductLensType.IMPRESSION_DNEYE_ERGO
  );

  const [productLensType2, setProductLensType2] = useState<NearVisionProductLensType | ProgressiveProductLensType>(
    NearVisionProductLensType.MULTIGRESSIV_DNEYE_ERGO
  );

  const [defaultFoVInputParameters] = useState<FoVInputParameters>({
    addition1: 0.75,
    addition2: 0.75,
    pantoscopicTilt: 7,
    faceFormAngle: 5,
    corneaVertexDistance: 13,
    pupillaryDistance: 64,
    df: 4,
    dn1: -16,
    dn2: -16,
    designCharacteristicFar: 33,
    designCharacteristicIntermediate: 33,
    designCharacteristicNear: 33,
    farDistance: 250,
    nearDistance1: 40,
    nearDistance2: 40,
    xSeparationFactor1: 0,
    xSeparationFactor2: 0,
    verticalOffset1: 0,
    verticalOffset2: 0,
  });

  const [formInputParameters] = useState<FormInputParameter[]>([
    {
      name: "addition1",
      title: "Addition1 (ADD)",
      min: 0,
      max: 3.5,
      step: 0.25,
      readOnly: false,
      disabled: false,
    },
    {
      name: "addition2",
      title: "Addition2 (ADD)",
      min: 0,
      max: 3.5,
      step: 0.25,
      readOnly: false,
      disabled: false,
    },
    {
      name: "pantoscopicTilt",
      title: "Pantoscopic Tilt (PT)",
      min: -5,
      max: 20,
      step: 0.5,
      readOnly: false,
      disabled: false,
    },
    {
      name: "faceFormAngle",
      title: "Face Form Angle (FFA)",
      min: -5,
      max: 20,
      step: 0.5,
      readOnly: false,
      disabled: false,
    },
    {
      name: "corneaVertexDistance",
      title: "Cornea Vertex Distance (CVD)",
      min: 5,
      max: 30,
      step: 0.5,
      readOnly: false,
      disabled: false,
    },
    {
      name: "pupillaryDistance",
      title: "Pupillary Distance (PD)",
      min: 40,
      max: 80,
      step: 0.5,
      readOnly: false,
      disabled: false,
    },
    {
      name: "df",
      title: "Design Point Far (DF)",
      min: -4,
      max: 4,
      step: 0.5,
      readOnly: false,
      disabled: false,
    },
    {
      name: "dn1",
      title: "Design Point Near 1 (DN)",
      min: -20,
      max: -12,
      step: 0.5,
      readOnly: false,
      disabled: false,
    },
    {
      name: "dn2",
      title: "Design Point Near 2 (DN)",
      min: -20,
      max: -12,
      step: 0.5,
      readOnly: false,
      disabled: false,
    },
    {
      name: "designCharacteristicFar",
      title: "Design Characteristic Far",
      min: 1,
      max: 99,
      step: 1,
      readOnly: false,
      disabled: false,
    },
    {
      name: "designCharacteristicIntermediate",
      title: "Design Characteristic Intermediate",
      min: 1,
      max: 99,
      step: 1,
      readOnly: false,
      disabled: false,
    },
    {
      name: "designCharacteristicNear",
      title: "Design Characteristic Near",
      min: 1,
      max: 99,
      step: 1,
      readOnly: false,
      disabled: false,
    },
    {
      name: "farDistance",
      title: "Far Distance (FD)",
      min: 100,
      max: 500,
      step: 1,
      readOnly: false,
      disabled: false,
    },
    {
      name: "nearDistance1",
      title: "Near Distance 1 (ND)",
      min: 10,
      max: 100,
      step: 1,
      readOnly: false,
      disabled: false,
    },
    {
      name: "nearDistance2",
      title: "Near Distance 2 (ND)",
      min: 10,
      max: 100,
      step: 1,
      readOnly: false,
      disabled: false,
    },
  ]);

  useEffect(() => {
    switch (lensType) {
      case LensType.NEAR_VISION:
        setProductLensType1(NearVisionProductLensType.IMPRESSION_SENSITIVE_ERGO);
        setProductLensType2(NearVisionProductLensType.IMPRESSION_DNEYE_ERGO);
        break;

      case LensType.PROGRESSIVE:
        setProductLensType1(ProgressiveProductLensType.IMPRESSION_SENSITIVE);
        setProductLensType2(ProgressiveProductLensType.IMPRESSION_DNEYE);
        break;

      default:
        break;
    }
  }, [lensType]);

  const { control, setValue } = useForm({
    defaultValues: defaultFoVInputParameters,
  });

  const handleLensTypeChange = (event: any) => {
    setLensType(Number(event.target.value));
  };

  const handleRenderingModeChange = (event: any) => {
    setRenderingMode(Number(event.target.value));
  };

  const handleProductLensType1Change = (event: any) => {
    setProductLensType1(Number(event.target.value));
  };

  const handleProductLensType2Change = (event: any) => {
    setProductLensType2(Number(event.target.value));
  };

  const handleSunModeChange = (event: any) => {
    setSunMode(Boolean(event.target.checked));
  };

  const fovInputParameters = useWatch({
    control,
  }) as FoVInputParameters;

  function updateDesignCharacteristic(parameterName: string, newVal: number) {
    switch (parameterName) {
      case "designCharacteristicFar":
        fovInputParameters.designCharacteristicFar = newVal;
        break;

      case "designCharacteristicIntermediate":
        fovInputParameters.designCharacteristicIntermediate = newVal;
        break;

      case "designCharacteristicNear":
        fovInputParameters.designCharacteristicNear = newVal;
        break;

      default:
        break;
    }

    var dc: LensDesignCharacteristic = {
      far: fovInputParameters.designCharacteristicFar,
      middle: fovInputParameters.designCharacteristicIntermediate,
      near: fovInputParameters.designCharacteristicNear,
    };

    fovInputParameters.designCharacteristicFar = dc.far;
    fovInputParameters.designCharacteristicIntermediate = dc.middle;
    fovInputParameters.designCharacteristicNear = dc.near;
    setValue("designCharacteristicFar", dc.far);
    setValue("designCharacteristicIntermediate", dc.middle);
    setValue("designCharacteristicNear", dc.near);
  }

  function mapLensTypeToProductLensType(lensType: LensType) {
    switch (lensType) {
      case LensType.NEAR_VISION:
        return NearVisionProductLensType;

      case LensType.SINGLE_VISION:
        return SingleVisionProductLensType;

      case LensType.PROGRESSIVE:
        return ProgressiveProductLensType;

      case LensType.SPORT:
        return SportProductLensType;
    }
  }

  return (
    <>
      <Grid container style={{ height: "90vh", padding: 20 }}>
        <Grid
          item
          lg={6}
          xs={6}
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className={classes.formControl}>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={3}>
                Lens Type / Product Lens Type
              </Grid>
              <Grid item xs={3}>
                <Select
                  id="lensType"
                  label="Lens type"
                  labelId="label-lens-type"
                  value={lensType}
                  onChange={handleLensTypeChange}
                >
                  {Object.keys(LensType)
                    .filter((key) => !isNaN(Number(key)))
                    .map((t) => {
                      if (Number(t) === LensType.PROGRESSIVE || Number(t) === LensType.NEAR_VISION) {
                        return <MenuItem value={t}>{LensType[Number(t)]}</MenuItem>;
                      }
                      return "";
                    })}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <Select
                  id="productLensType1"
                  label="Product Lens type 1"
                  labelId="label-product-lens-type1"
                  value={productLensType1}
                  onChange={handleProductLensType1Change}
                >
                  {Object.keys(mapLensTypeToProductLensType(lensType))
                    .filter((key) => !isNaN(Number(key)))
                    .map((t) => {
                      switch (lensType) {
                        case LensType.PROGRESSIVE:
                          return <MenuItem value={t}>{ProgressiveProductLensType[Number(t)]}</MenuItem>;

                        default:
                          return <MenuItem value={t}>{NearVisionProductLensType[Number(t)]}</MenuItem>;
                      }
                    })}
                </Select>
                <Select
                  id="productLensType2"
                  label="Product Lens type 2"
                  labelId="label-product-lens-type2"
                  value={productLensType2}
                  onChange={handleProductLensType2Change}
                >
                  {Object.keys(mapLensTypeToProductLensType(lensType))
                    .filter((key) => !isNaN(Number(key)))
                    .map((t) => {
                      switch (lensType) {
                        case LensType.PROGRESSIVE:
                          return <MenuItem value={t}>{ProgressiveProductLensType[Number(t)]}</MenuItem>;

                        default:
                          return <MenuItem value={t}>{NearVisionProductLensType[Number(t)]}</MenuItem>;
                      }
                    })}
                </Select>
              </Grid>
              -
              <Grid />
              <Grid item xs={3}>
                Rendering mode
              </Grid>
              <Grid item xs={3}>
                <Select
                  id="renderingMode"
                  label="Rendering mode"
                  labelId="label-rendering-mode"
                  value={renderingMode}
                  onChange={handleRenderingModeChange}
                >
                  {Object.keys(FovRenderingMode)
                    .filter((key) => !isNaN(Number(key)))
                    .map((t) => {
                      return <MenuItem value={t}>{FovRenderingMode[Number(t)]}</MenuItem>;
                    })}
                </Select>
              </Grid>
              <Grid item xs={3}>
                Sun mode
              </Grid>
              <Grid item xs={3}>
                <Checkbox id="sunMode" checked={sunMode} onChange={handleSunModeChange} />
              </Grid>
              {formInputParameters.map((p) => {
                return (
                  <Controller
                    key={p.name}
                    name={p.name}
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                      <Grid container item xs={12} spacing={3}>
                        <Grid item xs={3}>
                          {p.title}
                        </Grid>
                        <Grid item xs={3}>
                          <Slider
                            value={Number(value)}
                            ref={ref}
                            onChange={(_, newValue) => {
                              onChange(newValue);
                              updateDesignCharacteristic(p.name, Number(newValue));
                            }}
                            className={classes.slider}
                            disabled={p.disabled}
                            min={p.min}
                            max={p.max}
                            step={p.step}
                            aria-labelledby={`${p.name}-input-slider`}
                          />
                        </Grid>
                        <Grid item>
                          <Input
                            value={value}
                            ref={ref}
                            onChange={(event) => {
                              onChange((event.target as HTMLInputElement).valueAsNumber);
                              updateDesignCharacteristic(p.name, (event.target as HTMLInputElement).valueAsNumber);
                            }}
                            className={classes.input}
                            margin="dense"
                            readOnly={p.readOnly}
                            inputProps={{
                              min: p.min,
                              max: p.max,
                              step: p.step,
                              type: "number",
                              "aria-labelledby": `${p.name}-input`,
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  />
                );
              })}
            </Grid>
            <input type="submit" hidden />
          </form>
        </Grid>
        <Grid
          item
          lg={6}
          xs={6}
          style={{
            display: "flex",
            alignItems: "center",
            // alignSelf: 'center',
            flexDirection: "column",
            height: "70vh",
            width: "70vw",
            backgroundColor: "#0C2340",
          }}
        >
          <FieldOfView
            fovInputParameters={fovInputParameters}
            lensType={lensType}
            productLensType1={productLensType1}
            productLensType2={productLensType2}
            displayMode={FovDisplayMode.STANDARD}
            renderingMode={renderingMode}
            frameShade={FovFrameShade.LIGHT}
            sunMode={sunMode}
            elementId="prog-fov"
            newSVScenario={SVScenario.GRID}
            newSVPlusMode={false}
          />
        </Grid>
      </Grid>
    </>
  );
}
