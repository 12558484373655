import { makeStyles } from "@material-ui/core/styles";
import {
  FoVInputParameters,
  FormInputParameterSingleVision,
  LensType,
  SingleVisionProductLensType,
  SportProductLensType,
} from "../field-of-view/model";
import { useState } from "react";
import { Checkbox, Grid, Input, MenuItem, Select, Slider } from "@material-ui/core";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FieldOfView, FovDisplayMode, FovFrameShade, FovRenderingMode } from "../field-of-view/field-of-view";
import { SVScenario } from "../field-of-view-renderers/webGL/single-vision-scene";

/**
 * Default params for SV
 */
const defaultPT = 8;
const defaultCVD = 13;
const defaultFFA = 5;

export function FieldOfViewSingleVisionForm() {
  const useStyles = makeStyles((theme: any) => ({
    input: {
      width: 50,
    },
    slider: {
      width: 150,
    },
    formControl: {
      textAlign: "left",
      width: "100%",
    },
  }));
  const classes = useStyles();

  const [defaultFoVInputParameters] = useState<FoVInputParameters>({
    addition1: 0.75,
    addition2: 0.75,
    pantoscopicTilt: defaultPT,
    faceFormAngle: defaultFFA,
    corneaVertexDistance: defaultCVD,
    pupillaryDistance: 64,
    df: 4,
    dn1: -16,
    dn2: -16,
    designCharacteristicFar: 33,
    designCharacteristicIntermediate: 33,
    designCharacteristicNear: 33,
    farDistance: 250,
    nearDistance1: 40,
    nearDistance2: 40,
    xSeparationFactor1: 0,
    xSeparationFactor2: 0,
    verticalOffset1: 0,
    verticalOffset2: 0,
  });

  const [formInputParameters] = useState<FormInputParameterSingleVision[]>([
    {
      name: "pantoscopicTilt",
      title: "Pantoscopic Tilt (PT)",
      min: -5,
      max: 20,
      step: 0.5,
      readOnly: false,
      disabled: false,
    },
    {
      name: "faceFormAngle",
      title: "Face Form Angle (FFA)",
      min: -5,
      max: 15,
      step: 0.5,
      readOnly: false,
      disabled: false,
    },
    {
      name: "corneaVertexDistance",
      title: "Cornea Vertex Distance (CVD)",
      min: 5,
      max: 30,
      step: 0.5,
      readOnly: false,
      disabled: false,
    },
  ]);

  //const onSubmit = (data: any) => console.log(data);
  const [lensType] = useState<LensType>(LensType.SINGLE_VISION);
  const [renderingMode, setRenderingMode] = useState<FovRenderingMode>(FovRenderingMode.FULL);
  const [scenario, setScenario] = useState<SVScenario>(SVScenario.GRID);
  const [plusMode, setPlusMode] = useState<boolean>(false);
  const [productLensType1, setProductLensType1] = useState<SingleVisionProductLensType | SportProductLensType>(
    SingleVisionProductLensType.IMPRESSION_SENSITIVE_MONO
  );
  const [productLensType2, setProductLensType2] = useState<SingleVisionProductLensType | SportProductLensType>(
    SingleVisionProductLensType.NONE
  );

  const { control } = useForm({
    defaultValues: defaultFoVInputParameters,
  });

  const handleProductLensType1Change = (event: any) => {
    setProductLensType1(Number(event.target.value));
  };

  const handleProductLensType2Change = (event: any) => {
    setProductLensType2(Number(event.target.value));
  };

  const handleRenderingModeChange = (event: any) => {
    setRenderingMode(Number(event.target.value));
  };

  const handleScenarioChange = (event: any) => {
    setScenario(Number(event.target.value));
  };

  const handlePlusModeChange = (event: any) => {
    setPlusMode(Boolean(event.target.checked));
  };

  const fovInputParameters = useWatch({
    control,
  }) as FoVInputParameters;

  function mapLensTypeToProductLensType(lensType: LensType) {
    switch (lensType) {
      case LensType.SINGLE_VISION:
        return SingleVisionProductLensType;

      case LensType.SPORT:
        return SportProductLensType;

      default:
        return SingleVisionProductLensType;
    }
  }

  return (
    <>
      <Grid container style={{ height: "90vh", padding: 20 }}>
        <Grid
          item
          lg={6}
          xs={6}
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className={classes.formControl}>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={3}>
                Product Lens Type
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <Select
                  id="productLensType1"
                  label="Product Lens type 1"
                  labelId="label-product-lens-type1"
                  value={productLensType1}
                  onChange={handleProductLensType1Change}
                >
                  {Object.keys(mapLensTypeToProductLensType(lensType))
                    .filter((key) => !isNaN(Number(key)))
                    .map((t) => {
                      switch (lensType) {
                        case LensType.SINGLE_VISION:
                          return <MenuItem value={t}>{SingleVisionProductLensType[Number(t)]}</MenuItem>;

                        case LensType.SPORT:
                          if (Number(t) < 3) return <></>;
                          return <MenuItem value={t}>{SportProductLensType[Number(t)]}</MenuItem>;

                        default:
                          return <MenuItem value={t}>{SingleVisionProductLensType[Number(t)]}</MenuItem>;
                      }
                    })}
                </Select>
                <Select
                  id="productLensType2"
                  label="Product Lens type 2"
                  labelId="label-product-lens-type2"
                  value={productLensType2}
                  onChange={handleProductLensType2Change}
                >
                  {Object.keys(mapLensTypeToProductLensType(lensType))
                    .filter((key) => !isNaN(Number(key)))
                    .map((t) => {
                      switch (lensType) {
                        case LensType.SINGLE_VISION:
                          return <MenuItem value={t}>{SingleVisionProductLensType[Number(t)]}</MenuItem>;

                        case LensType.SPORT:
                          if (Number(t) < 3) return <></>;
                          return <MenuItem value={t}>{SportProductLensType[Number(t)]}</MenuItem>;

                        default:
                          return <MenuItem value={t}>{SingleVisionProductLensType[Number(t)]}</MenuItem>;
                      }
                    })}
                </Select>
              </Grid>
              -
              <Grid />
              <Grid item xs={3}>
                Rendering mode
              </Grid>
              <Grid item xs={3}>
                <Select
                  id="renderingMode"
                  label="Rendering mode"
                  labelId="label-rendering-mode"
                  value={renderingMode}
                  onChange={handleRenderingModeChange}
                >
                  {Object.keys(FovRenderingMode)
                    .filter((key) => !isNaN(Number(key)))
                    .map((t) => {
                      return <MenuItem value={t}>{FovRenderingMode[Number(t)]}</MenuItem>;
                    })}
                </Select>
              </Grid>
              <Grid item xs={3}>
                Scenario
              </Grid>
              <Grid item xs={3}>
                <Select
                  id="scenario"
                  label="Scenario"
                  labelId="label-scenario"
                  value={scenario}
                  onChange={handleScenarioChange}
                >
                  {Object.keys(SVScenario)
                    .filter((key) => !isNaN(Number(key)))
                    .map((t) => {
                      return <MenuItem value={t}>{SVScenario[Number(t)]}</MenuItem>;
                    })}
                </Select>
              </Grid>
              <Grid item xs={3}>
                Plus mode
              </Grid>
              <Grid item xs={3}>
                <Checkbox id="plusMode" checked={plusMode} onChange={handlePlusModeChange} />
              </Grid>
              {formInputParameters.map((p) => {
                return (
                  <Controller
                    key={p.name}
                    name={p.name}
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                      <Grid container item xs={12} spacing={3}>
                        <Grid item xs={3}>
                          {p.title}
                        </Grid>
                        <Grid item xs={3}>
                          <Slider
                            value={Number(value)}
                            ref={ref}
                            onChange={(_, newValue) => {
                              onChange(newValue);
                            }}
                            className={classes.slider}
                            disabled={p.disabled}
                            min={p.min}
                            max={p.max}
                            step={p.step}
                            aria-labelledby={`${p.name}-input-slider`}
                          />
                        </Grid>
                        <Grid item>
                          <Input
                            value={value}
                            ref={ref}
                            onChange={(event) => {
                              onChange((event.target as HTMLInputElement).valueAsNumber);
                            }}
                            className={classes.input}
                            margin="dense"
                            readOnly={p.readOnly}
                            inputProps={{
                              min: p.min,
                              max: p.max,
                              step: p.step,
                              type: "number",
                              "aria-labelledby": `${p.name}-input`,
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  />
                );
              })}
            </Grid>
            <input type="submit" hidden />
          </form>
        </Grid>
        <Grid
          item
          lg={6}
          xs={6}
          style={{
            display: "flex",
            alignItems: "center",
            // alignSelf: 'center',
            flexDirection: "column",
            height: "70vh",
            width: "70vw",
            backgroundColor: "#0C2340",
          }}
        >
          <FieldOfView
            fovInputParameters={fovInputParameters}
            lensType={lensType}
            productLensType1={productLensType1}
            productLensType2={productLensType2}
            displayMode={FovDisplayMode.STANDARD}
            renderingMode={renderingMode}
            frameShade={FovFrameShade.LIGHT}
            sunMode={false}
            elementId="sv-fov"
            newSVScenario={scenario}
            newSVPlusMode={plusMode}
          />
        </Grid>
      </Grid>
    </>
  );
}
